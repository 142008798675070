import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import urlBuilder from "@sanity/image-url"
import BlockContent from "@sanity/block-content-to-react"

import PageHeader from "../components/PageHeader"
import Layout from "../components/layout"

export const query = graphql`
  query($slug: String) {
    site {
      siteMetadata {
        title
      }
    }
    sanityBlogPost(slug: { current: { eq: $slug } }) {
      title
      author {
        name
      }
      categories {
        title
      }
      publishedAt
      mainImage {
        imageName
        imageDescription
        imageUpload {
          asset {
            id
          }
          hotspot {
            x
            y
          }
        }
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
  }
`

function blogPost({ data, pageContext }) {
  const blogPost = data.sanityBlogPost
  const blogPostHero = data.sanityBlogPost.mainImage.imageUpload

  const urlFor = (source) =>
    urlBuilder({ projectId: "yk6mnr40", dataset: "production" }).image(source)

  const sectionText = css`
    max-width: 1000px;
  `

  const blockRenderer = ({ node, children }) => {
    switch (node.style) {
      case "h1":
        return (
          <div css={sectionText}>
            <h1>{children}</h1>
          </div>
        )
      case "h2":
        return (
          <div css={sectionText}>
            <h2>{children}</h2>
          </div>
        )
      case "h3":
        return (
          <div css={sectionText}>
            <h3>{children}</h3>
          </div>
        )
      case "h4":
        return (
          <div css={sectionText}>
            <h4>{children}</h4>
          </div>
        )
      case "h5":
        return (
          <div css={sectionText}>
            <h5>{children}</h5>
          </div>
        )
      case "h6":
        return (
          <div css={sectionText}>
            <h6>{children}</h6>
          </div>
        )
      case "blockquote":
        return (
          <div css={sectionText}>
            <blockquote>{children}</blockquote>
          </div>
        )
      default:
        return (
          <div css={sectionText}>
            <p>{children}</p>
          </div>
        )
    }
  }

  return (
    <Layout>
      <PageHeader
        overlay
        backgroundImage={blogPostHero}
        title={blogPost.title}
      />
      <div className="sectionContent" style={{ maxWidth: "1000px" }}>
        <div>
          <BlockContent
            blocks={blogPost._rawBody}
            serializers={{
              types: { block: blockRenderer },
            }}
            // className="projectContent"
          />
        </div>
      </div>
    </Layout>
  )
}

export default blogPost
